/* Table only styles */

.ggg .blues {
  background-color: navy;
  color: white;
  cursor: pointer;
}

.ggg .greens {
  background-color: darkgreen;
  color: white;
  cursor: pointer;
}

.ggg .greens:hover {
  background-color: greenyellow;
  color: black;
}

.ggg .reds {
  background-color: red;
  color: red!important;
  cursor: pointer;
}

.ggg .blacks {
  background-color: black;
  color: black!important;
  cursor: pointer;
}

.ggg .cella  {
  width: 35px!important;
  height: 35px!important;
  font-size: 12px;
  color: white;
  text-align: center;
  cursor: pointer;
  position: relative;
  
}

.ggg .chip-container-cella {
  line-height: 35px;
  text-align: center;
  
}

.ggg .cella-z-top  {
  width: 35px!important;
  height: 60px!important;
  font-size: 12px;
  color: white;
  text-align: center;
  cursor: pointer;
  
  position: relative;
  clip-path: polygon(50% 0, 100% 0, 100% 100%, 0% 100%);
}

.ggg .cella-z-bottom  {
  width: 35px!important;
  height: 64px!important;
  font-size: 12px;
  color: white;
  text-align: center;
  cursor: pointer;
  
  position: relative;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 100%);
}

.ggg .chip-container-cella-z {
  line-height: 76px;
  text-align: center;

}

.ggg .cella-fourth  {
  width: 158px!important;
  height: 35px!important;
  font-size: 12px;
  color: white;
  text-align: center;
  cursor: pointer;
  position: relative;
  
}



.ggg .chip-container-cella-fifth {
  line-height: 35px;
  text-align: center;
  justify-content: center;
  justify-items: center;

}

.ggg .cella-fifth  {
  width: 76px!important;
  height: 35px!important;
  font-size: 12px;
  color: white;
  text-align: center;
  cursor: pointer;
  position: relative;


}

.ggg .chip-container-cella-fourth {
  line-height: 35px;
  text-align: center;

}

.ggg .bordo {
  background-color: green;
  width: 6px;
  max-width: 6px;
  height: 35px;
  font-size:1px;
  color: green;
  cursor: pointer;
  
}

.ggg .bordo .chip {
  margin-left: -8px;
  
}

.ggg .chip-container-bordo {
  line-height: 35px;
  position: relative;
  visibility: hidden;
  
}

.ggg .bordo:hover {
  background-color: yellow;
  color: yellow;
  visibility: hidden;
  
}

.ggg .bordo-h {
  background-color: green;
  height: 6px;
  max-height: 6px;
  width: 35px!important;
  font-size:1px;
  color: green;
  cursor: pointer;
  
}

.ggg .bordo-h .chip {
  margin-top: -6px;
  margin-left: 12px;
  
}

.ggg .chip-container-bordo-h {
  line-height: 6px;
  position: relative;
  visibility: hidden;

}

.ggg .bordo-h:hover {
  background-color: yellow;
  color: yellow;
}

.ggg .space {
  width: 6px;
  height: 6px;
  background-color: #F2F098;
}

.ggg .bet-space {
  width: 6px;
  height: 6px;
  background-color: orange;
  cursor: pointer;
  font-size: 0;
  visibility: hidden;
}

.ggg .bet-space:hover {
  background-color: yellow;
}

.ggg .bet-space .chip {
  margin-top: -6px;
  margin-left: -6px;
  
}

.ggg .space-fourth {
  width: 6px;
  height: 35px;
  background-color: #F2F098;
  visibility: hidden;
}

.ggg .space-2by1, .space-z {
  width: auto;
  height: 6px;
  background-color: #F2F098;
  margin: 0;
  padding: 0;
}

.ggg .table-divider {
  height: 6px!important;
  width: 100%!important;
  background-color: #F2F098;
  margin: 0!important;
}
.tbl-divider{
  height: 6px!important;
  width: 66%!important;
  background-color: #F2F098;
margin-left: 83px !important;
}
.ggg .divider-50 {
  height: 6px!important;
  width: 50%!important;
  background-color: #F2F098;
}

.ggg .divider-invisible {
  height: 6px!important;
  width: 100%!important;
  background-color: transparent!important;
}

.tbl-dividerr{
  width: 66% !important;
  height: 6px!important;
  /* width: 66%!important; */
  background-color: #F2F098;
margin-left: 83px !important;
}
.ggg .red {
  background-color: red!important;
}

.ggg .black {
  background-color: black!important;
}

.ggg ul {
  margin: 0!important;
  justify-items: center;
  justify-content: center;
}

.ggg .no-cursor {
  cursor: not-allowed;
}

.ggg .roulette-table button {
  border: none!important;
  outline: none!important;
  margin: 0!important;
  padding: 0!important;
}
.ggg .tablee{
  background: linear-gradient(to bottom, #fff0, #fff0, #fff0) !important;
}

.ggg .tableee.row {
  transform: translate(0px, 100px);
}
