.live-casino-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
  }

  .video-containers {
    width: 100%;
    max-width: 870px; /* Adjust as needed */
    margin-bottom: 0px;
  }
  .ader_b_box.amar_bg1 p {
    line-height: 3.1 !important;
}
.ader_b_box.amar_bg2 p {
  line-height: 3.1 !important;
}
.slider_cardamar {
  position: absolute;
  top: 30px;
  background: #0000006b;
  padding: 5px;
  width: 60px;
}
.slider_time{
  position: absolute;
  bottom: 0px;
  right: 0;
  background: #0000006b;
  padding: 5px;
  width: 60px;
}
.slider_time p{
  padding: 5px;
  font-size: 25px !important;
  font-weight: 600;
  color: #fff;
}
  .card-toggle-btn {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 20px;
  }

  .card-info {
    font-size: 18px;
    color: #333;
  }
.ander_bahar p {
    margin-bottom: 0px;
    text-align: center;
    font-size: small;
}
.ader_b_box {
    background: #e9e9e9;
}
.redzone p {
    color: red;
}

.ader_b_box {
    position: relative;
}
.box-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 69%);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    border-radius: 0px;
}
.ader_b_box {
    background: #aec0db;
    color: #fff;
    font-weight: 700;
}
.ader_b_box p {
    font-weight: 700;
}
.icon {
    position: absolute;
    top: 5px;
    right: 0;
    left: 0;
    margin: auto;
    width: 22px;
    height: 29px;
    background-size: cover;
    background-image: url(/public/images/anderlock.svg);
}
.title_ander h2 {
    font-size: 24px;
    font-weight: 600;
}
.Result_v {
  text-align: start !important;
  font-weight: 600;
  color: #fff;
}
.statment_v {
  text-align: end !important;
  font-weight: 600;
  color: #fff;
}
.bg_rusls{
  background-color: #2d90d4;
}
.border_carander {
    border: 5px solid #2d90d4;
}
.redzonesx p{
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  align-items: center;
}
.deckcard {
  background: #ffffff;
    color: #f00;
    font-weight: 700;
}
.pricecard_sec {
  text-align: center;
  font-size: 20px !important;
  font-weight: 600;
}
.icons {
  top: 30px;
}
  @media (max-width: 768px) {
    .card-info {
      font-size: 16px;
    }
    .card-toggle-btn {
      font-size: 14px;
      padding: 8px 16px;
    }
  }

  