body {
    font-family: Arial, sans-serif;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th, td {
    padding: 12px;
    text-align: center;
    border: 1px solid #0a0909;
}
th {
    background-color:  hsl(208.02deg 100% 67.25%);
    color: #fff;
}
.header {
    margin-top: 0px;
    margin-bottom: 0px;
    background: #f7f7f7;
    padding: 10px 0;
    }

    .d-flex.justify-content-center a.btn-primary {
        background-color: hsl(338.43deg 100% 70%)!important;
        border-color: hsl(338.43deg 100% 70%) !important;
    }
.logoout button {
    background: hsl(338.43deg 100% 70%);
    border: 0;
    border-radius: 5px;
    padding: 10px 40px;
    font-size: 18px;
    font-weight: 500;
    color: #ffff;
    margin-left: 80%;
    }
 
    .bg_new .row {
        align-items: center;
        }
        .backto{
    text-decoration: none;
    color: #fff;
}
.table-fill td{
    /* background-color: #f5f5f5; */
    border: 1px solid #000;
}