@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;600;700&family=Lexend+Deca:wght@100;200;300;400;500;600;700&family=Montserrat:wght@400;700&family=Mulish:wght@300&family=Poppins:wght@300;400;600&family=Urbanist:wght@300;400;600;700;800;900&display=swap');



.menuContainer{
    /* height: 100vh; */
    background: url(../../assets/image/ludoBordbackground.jpg) no-repeat 0 0;
    background-size: cover;
   
}
.menu{
    border: 2px solid #ffffff3d;
}
.menuContainer{
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.startMenu{
    background-clip: content-box;
    backdrop-filter: blur(1px);
    overflow: hidden;
    height: 10px;
    color: white    ;
    background-color: #000000d4;
    
    /* border: 2px solid red; */
    animation: opening 0.5s linear forwards;

}
/* 480px */
@keyframes opening {
    0% {
        height: 10px;
    }
    99%{
        height: 490px;
    }
    100% {
        height: auto;
    }
}
@keyframes closing {
    0% {
        height: 490px;
    }
    
    100% {
        height:0px;
    }
}
.choosePlayers{
    display: flex;
    align-items: center;
    justify-content: center;
}
.players{
   
    margin: 2px ;
   
}

.players img{
    height: 75px;
}

/* .btn{
    width: 90px;
} */
.selected{
    border: 1px solid rgb(255, 255, 255);
    background: rgba(128, 128, 128, 0.511);
    
}
.players::after{
    content: "";
    display: block;
    height: 2px;
    width: 0%;
    text-align: center;
    margin: auto;
   
}
.redPlayer::after{
    background-color: #ff0000;
    box-shadow: 0px 0px 20px 17px #ff0000;
}
.bluePlayer::after{
    background-color: blue;
    box-shadow: 0px 0px 20px 17px blue;
}
.greenPlayer::after{
    background-color: green;
    box-shadow: 0px 0px 20px 17px green;
}
.yellowPlayer::after{
    background-color: yellow;
    box-shadow: 0px 0px 20px 17px yellow;
}
h1::after{
    content: "";
    display: block;
    height: 2px;
    width: 21%;
    background-color: #8a57ea;
    text-align: center;
    margin: auto;
    box-shadow: 0 0 20px 7px #8a57ea;

}

  /* Remove hover styles for touch devices */
 
@media(max-width: 576px){
    body{
        background-position: center;
    }
}
@media(max-width: 350px){
    .dice{
            width: 80%;
    }
}

