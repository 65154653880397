.sticky-header {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    padding: 10px 0;
    left: 0;
    right: 0;
  }
  
  .sticky-header .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .sticky-header .menu ul {
    display: flex;
    gap: 20px;
    margin: 0;
    padding: 0;
    list-style: none;
  }
  a{
    color: #fff;
  }
  .sticky-header .menu ul li {
    list-style: none;
  }
  
  .sticky-header .menu ul li a {
    text-decoration: none;
    color: #333333;
    font-weight: bold;
  }
  
  .sticky-header .menu ul li a:hover {
    color: #ff1616;
  }
  .profilenmae {
    text-align: end;
    display: flex;
    justify-content: flex-end;
}

.logout button {
  background: #97173a;
  border: 0;
  border-radius: 5px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  /* margin-left: 80%; */
  padding: 10px 40px;
}
.profilenmae .name-tx {
  background: #212529;
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  box-shadow: 1px 1px 5px #e8e8e8;
  color: #fff;
  margin-bottom: 10px;
  margin-left: 20px;
  padding: 2px 26px;
}


.profile-name .name-tx {
    background: #212529;
    border: 1px solid #d6d6d6;
    border-radius: 4px;
    box-shadow: 1px 1px 5px #e8e8e8;
    color: #fff;
    /* margin-bottom: 10px; */
    margin-left: 20px;
    padding: 2px 26px;
    /* width: 200PX; */
}
.profile-name .name-tx {
  display: inline-flex;
  left: 0;
  right: 0;
  margin-right: auto;
}
.profile-nmae {
  display: flex;
  justify-content: flex-end;
  text-align: end;
}
.profile-name {
  justify-content: flex-end;
  text-align: right;
}
.profile-section {
  position: fixed;
  top: 100px;
  width: 100%;
  background-color: #e6e6e6;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  z-index: 1000;
  padding: 10px 0;
  left: 0;
  right: 0;
}
.header .logo {
  width: 200px;
}
.header .logo img {
  width: 100%;
}
@media (max-width: 767px) {
  .profilenmae .name-tx {
      padding: 2px 8px;
  }
  .header .logo {
    width: 130px;
}
}