.slider_time{
    position: absolute;
    bottom: 0px;
    right: 0;
    background: #0000006b;
    padding: 5px;
    width: 80px !important;
  }
  .slider_time p{
    padding: 5px;
    font-size: 25px !important;
    font-weight: 600;
    color: #fff;
  }
  .margeing img {
    margin-right: 5px;
  }
  .dt_tiger .ader_b_box p {
    font-weight: 700;
    line-height: 3;
}
.slider_cardamar {
  width: 90px;
}