/* Weel only styles */

.roulette-canvas {
  margin: -8px;;
}

.roulette-container  {
  width: 400px;
  height: 400px;
  position: relative;
  left:5px;
  top:5px;
  background: #000000 url("../../assets/image/roulette-bg.png") top center no-repeat;
  background-size: contain;
  border-radius: 50%;
  margin: 0 auto;
}

.spin-button {
  border-radius: 50%;
  border: 5px solid #fff294;
  width: 200px;
  height: 200px;
  position: absolute;
  top: 92px;
  left: 92px;
  background: url("../../assets/image/button-bg.png") center center no-repeat;
  background-size: cover;
  color: #121212;
}


.text-black-ish {
  color: #121212;
}

.blinky-number {
  font-family: 'Anton', sans-serif;
  font-weight: 500;
  animation: blinky-number 1s ease infinite;
  -moz-animation: blinky-number 1s ease infinite;
  -webkit-animation: blinky-number 1s ease infinite;
}

@keyframes blinky-number {
  0%,
  100% {
    color: #ff073a;
  }
  50% {
    color: #fff294;
  }
}

#spinn{
  background-color: #000000 !important;
}