/* General styles */

  
 
  /* Section layout */
  section {
    display: grid;
    gap: 1.5rem;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    margin: 0rem auto 1rem;
    padding: 0 1rem;
}

.changeForm{
  margin-top: 20px;
}
.gameMatch{
margin-top: 180px;
}
  
  /* Article styles */
  article {
    margin: 0;
  }
  
  article img {
    width: 100%;
    height: auto;
    border-radius: 0.25rem;
  }
  
  article h2 {
    color: oklch(25% 0.104 146.51);
    font-size: clamp(0.75rem, 4vw, 1rem); /* Responsive font size */
    font-weight: 300;
    letter-spacing: 0.1em;
    line-height: 1.1;
    margin: clamp(0.125rem, 2vw, 0.375rem) 0;
  }
  
  article .categories {
    border-radius: 0.25rem;
    font-size: clamp(0.4rem, 2.5vw, 0.625rem); /* Responsive font size */
    letter-spacing: 0.15em;
    line-height: 1;
    margin: 0;
    padding: clamp(0.125rem, 1.5vw, 0.25rem); /* Responsive padding */
    font-variant: small-caps;
  }
  .hero_area__topslider-card {
    border-radius: 8px;
    border: 1px solid rgb(98 98 98 / 17%);
    transition: .4s linear;
    background: #f7f7f7;
    margin-bottom: 20px;
    box-shadow: 1px 5px 10px #e5e5e5;
}
.media-body {
  cursor: pointer;
}
.LIVKADD {
  cursor: pointer;
}
.n5-color {
  color: rgba(var(--n5), 1);
}
.hero_area__topslider-vs .n5-color {
  padding: 10px 9px;
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid #8898aa;
  color: #ff669d;
}
.bg-default {
  border: 0;
}
.hero_area__topslider-cardtop.d-flex.align-items-center.justify-content-between.mb-2.mb-md-6 {
  padding: 3px 7px;
  background: #58b1ff;
  border-radius: 6px;
}
.date_gg {
  color: #fff;
}
.p2-bg{
  background-color: hsl(208.17deg 100% 67.84%);
  border-radius: 5px;
}
.hero_area__topslider-cardbody h6{
  color: #323232;
  font-weight: 600;
  text-transform: capitalize;
}
.sudpend_bb{
  background-color: #58b1ff;
  color: #000;
}
.fs-eight{
  color: #323232;
}
.fs-eight {
    font-size: 12px;
    line-height: 130%;
}
.bt-bg button{
  background:  hsl(208.17deg 100% 67.84%);
  color: #fff;
}
.bt-bg:hover button{
  background:  hsl(208.17deg 100% 67.84%);
  color: #fff;
}
.logos  {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.form_sec form {
  background: #fff;
}
  /* Media queries for responsive design */
  @media (max-width: 768px) {
    section {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
  }
  
  @media (max-width: 480px) {
    article h2 {
      font-size: clamp(0.75rem, 6vw, 1rem); /* Adjust font size for smaller screens */
    }
  
    article .categories {
      font-size: clamp(0.4rem, 4vw, 0.625rem); /* Adjust font size for smaller screens */
      padding: clamp(0.125rem, 2vw, 0.25rem); /* Adjust padding for smaller screens */
    }
  }
  .row {
    /* --bs-gutter-x: 1.5rem; */
    /* --bs-gutter-y: 0; */
    /* display: flex; */
    /* flex-wrap: wrap; */
    margin-top: auto !important;
    margin-right: auto !important;
    margin-left: auto !important;
}  

  .rows {
    margin-right: 15px;
    margin-left: 15px;
    margin-bottom: 20px;
  }
  .table-flush tbody tr td span {
    font-size: 11px;
  }
  .left_data {
    text-align: start;
  }
  .left_data {
    /* font-weight: 700 !important; */
    font-size: 16px !important;
    line-height: 1;
}
.media-body .mb-0.text-sm {
  font-size: 16px;
}
.media-body .money {
  font-size: 11px;
}
.profile_sec .table th, .table td {
  padding: 5px;
}
.red_tx {
  color: white;
  background: #58b1ff;
  border-radius: 5px;
  line-height: 1.4;
}
.blue_tx {
  color: white;
  background: #ff669d;
  border-radius: 5px;
  line-height: 1.4;
}
.logoout button {
  background: hsla(208, 100%, 67%, 0);
  border: 0;
  border-radius: 5px;
  padding: 0px 17px;
  font-size: 18px;
  font-weight: 500;
  color: #333;
  margin-left: 36%;
}
.left_tx{
  text-align: left;
}
.logoout button p {
  line-height: 2;
  font-size: 18px;
  margin-left: 4px;
  margin-bottom: 1px;
  color: #ff669d;
  font-weight: 600;
}
#tooltip-top p {
  margin-bottom: 0;
  font-size: 10px;
  font-weight: 500;
}
.custom-tooltip {
  background-color: #ff6347; /* Change to your desired background color */
  color: #fff; /* Tooltip text color */
  border: 1px solid #ff6347; /* Optional: border color matching the background */
  border-radius: 4px; /* Optional: rounded corners */
  padding: 5px 10px; /* Optional: padding inside the tooltip */
}

.tooltip-inner {
  text-align: center; /* Center-aligns the text within the tooltip */
}
.myLedger .media-body .mb-0.text-sm {
  font-size: small;
}
.table-flush tbody tr td span {
  font-size: small;
}

.myLedger .table-flush tbody tr td span {
  color: #000;
}
.statment_bt .media-body .mb-0.text-sm {
  font-size: small;
}
.statment_bt tbody tr td span {
  font-size: small;
  color: #000;
}

.statment_bt .media-body {
  cursor: pointer;
  text-align: start !important;
}
.statment_bt .media.align-items-center{
  text-align: start !important;
  
}
.scorecard {
  width: calc(100% - 8px);
  padding: 5px;
  background-image: url(/public/images/scorecard-bg.jpeg);
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  z-index: 1;
  margin-left: 4px;
  margin-right: 4px;
}
.scorecard::before {
  content: "";
  background-color: rgba(0, 0, 0, 0.55);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.game-header {
  background-color:#58b1ff;
  color: var(--text-secondary);
  padding: 5px 10px;
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: calc(100% - 8px);
  margin-left: 4px;
  margin-right: 4px;
}
.sudpend_bb {
  color: red !important;
}
.ball-runs {
  display: inline-block;
  height: 25px;
  line-height: 25px;
  width: 25px;
  border-radius: 50%;
  font-size: var(--font-small);
  background-color: #08c;
  color: #fff;
  text-align: center;
  margin-right: 4px;
}
.ball-runs.four {
  background-color: #087f23;
}
.ball-runs.six {
  background-color: #883997;
}
.ball-runs.out {
  background-color: #d10000;
}
.mobile_view{
 display: none;
}
.desktop_view{
  display: block;
}
.desktop_view p{
 margin-bottom: 0;
}
.completegame .cpoint{
 color: #000;
}
.complterd .hero_area__topslider-vs .n5-color {
  margin-top: 0px;
  margin-bottom: 0px;
}
.complterd .hero_area__topslider-cardfooter span{
  color: #fff;
}
.header_table{
  width: 50;
}
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.popup-content button {
  border: 0;
  background: #2196F3;
  color: #fff;
  border-radius: 5px;
  padding: 4px 20px;
}
.popup-content h2{
  font-weight: 600;
}
.text-center.text-white {
  text-transform: uppercase;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
  @media(max-width: 767px) {
    .left_tx .btn {
      width: 25px;
  }
    .mobile_view{
      display: block;
     }
     .desktop_view{
       display: none;
     }
     .mobile_view p{
      margin-bottom: 0;
     }
  .rows {
    margin-right: 0px;
    margin-left: 0px;
    margin-bottom: 20px;
    margin-top: 5px !important;
}
.table-flush tbody tr td span {
  font-size: 9px;
  color: #666;
}
.left_data {
  text-align: start;
}
.table {
  width: 100%;
}
.left_data {
  text-align: start;
  white-space: unset !important;
}
.desbord {
  padding: 0;
}
.video-container {
  border-radius: 10px;
}
.video-container img {
  border-radius: 10px;
}
.left_data .d-flex {
  align-items: center;
}
.team-1.row {
  margin-bottom: 0;
}
.team-1.row.mt-2 {
  margin-bottom: 0;
}
.game-header span {
  color: #fff;
  font-size: small;
}
.hero_area__main{
  padding: 0;
}
.amountt button {
  margin: 6px;
}
.myLedger .media-body .mb-0.text-sm {
  font-size: 9px;
}
.Toastify__toast {
  border-radius: 0;
  margin-bottom: 0;
  top: 400px;
  width: 220px;
  margin: auto;
}
.pading_fncyz{
  padding: 0;
}

  }