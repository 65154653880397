.video-container {
    position: relative;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
  }
  .video-container {
    position: relative;
    cursor: pointer;
    overflow: hidden;
    box-shadow: 1px 8px 14px #00000059;
    border-radius: 20px;
  }
  
  .video-container img {
    width: 100%;
    height: auto;
    transition: transform 0.3s ease;
    border-radius: 20px;
    
  }
  
  .video-container:hover img {
    transform: scale(1.1);
    border-radius: 20px;
  }
  
  .play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
  }
.box-price button {
    background: #212529;
    border-color: #212529;
    color: #fff;
    padding: 7px 20px;
}
.box-price button:hover {
  background: #97173a;
  border-color: #97173a;
  color: #fff;
  padding: 7px 20px;
}
.main-price p {
  font-weight: 600;
}
body {
  background: hsl(0deg 0% 100%);
}