
.table {
    width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
}
.tableee{
    margin-top: 150px;
}
.table th,
.table td {
    /* padding: 3px 7228x7Lpx !important; */
    vertical-align: top;
    border-top: 1px solid #e9ecef;
}

.amountt{
    background-color: #e9ecef;
}
.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #e9ecef;
}

.table tbody + tbody {
    border-top: 2px solid #e9ecef;
}

.table .table {
    background-color: #f8f9fe;
}

.table-dark,
.table-dark > th,
.table-dark > td {
    background-color: #c1c2c3;
}

.table .thead-dark th {
    color: #f8f9fe;
    border-color: #666;
    background-color: #172b4d;
}

.table .thead-light th {
    color: #8898aa;
    border-color: #e9ecef;
    background-color: #f6f9fc;
}

.table-dark {
    color: #f8f9fe;
    background-color: #b1b1b1;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
    border-color: #FFF;
}

.table-responsive {
    display: block;
    overflow-x: auto;
    width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}


a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
    background-color: #24a46d !important;
}

.bg-info {
    background-color: #11cdef !important;
}

a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
    background-color: #0da5c0 !important;
}

.bg-warning {
    background-color:  hsl(338.43deg 100% 70%) !important;
}

a.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
    background-color:  hsl(338.43deg 100% 70%) !important;
}

.bg-danger {
    background-color:  hsl(338.43deg 100% 70%) !important;
}

a.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
    background-color:  hsl(338.43deg 100% 70%) !important;
}

.bg-default {
    background-color: #172b4d !important;
}

a.bg-default:hover,
a.bg-default:focus,
button.bg-default:hover,
button.bg-default:focus {
    background-color: #0b1526 !important;
}

.bg-transparent {
    background-color: transparent !important;
}

.border-0 {
    border: 0 !important;
}

.rounded-circle {
    border-radius: 50% !important;
}

.d-flex {
    display: flex !important;
}

.justify-content-end {
    justify-content: flex-end !important;
}

.align-items-center {
    align-items: center !important;
}

@media (min-width: 1200px) {

    .justify-content-xl-between {
        justify-content: space-between !important;
    }
}

.sr-only {
    position: absolute;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    width: 1px;
    height: 1px;
    padding: 0;
    white-space: nowrap;
    border: 0;
}

.page-item.active .page-link {
    box-shadow: 0 7px 14px rgba(50, 50, 93, .1), 0 3px 6px rgba(0, 0, 0, .08);
}

.page-item .page-link,
.page-item span {
    font-size: .875rem;
    display: flex;
    width: 36px;
    height: 36px;
    margin: 0 3px;
    padding: 0;
    border-radius: 50% !important;
    align-items: center;
    justify-content: center;
}


.table thead th {
    font-size: .65rem;
    padding-top: .75rem;
    padding-bottom: .75rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    border-bottom: 1px solid #e9ecef;
}

.table th {
    font-weight: 600;
}
/* statementStyle.css */



.table td,
.table th {
    font-size: .8125rem;
    /* white-space: nowrap; */
    white-space: normal;
}

.table.align-items-center td,
.table.align-items-center th {
    vertical-align: middle;
}

.table .thead-dark th {
    color: #ffffff;
    background-color:  hsl(208.02deg 100% 67.25%)
}
.table .thead-light th {
    color: #8898aa;
    background-color: #f6f9fc;
}

.table-flush td,
.table-flush th {
    border-right: 0;
    border-left: 0;
}

.table-flush tbody tr:first-child td,
.table-flush tbody tr:first-child th {
    border-top: 0;
}

.table-flush tbody tr:last-child td,
.table-flush tbody tr:last-child th {
    border-bottom: 0;
}

.card .table {
    margin-bottom: 0;
}

.card .table td,
.card .table th {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
}

p {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.7;
}

.table>:not(caption)>*>* {
    padding: .5rem .5rem;
    color: var(--bs-table-color-state, var(--bs-table-color-type, #000));
    background-color: #ffffff;
    border-bottom-width: var(--bs-border-width);
    box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
    font-family: 'Poppins';
    font-weight: 600;
}

@media (max-width: 768px) {
    .btn {
        margin-bottom: 10px;
    }
}
.profile_sec .table th, .table td {
    padding: 1rem;
    vertical-align: top;
    border: 1px solid #b1b1b1;
}
.top_matches__main.top_matches__main2 {
    margin: 0px 0 0 303px;
}
.ball4 {
    background-color: #2d90d4;
    border: 1px solid #2d90d4;
    border-radius: 50px;
    color: #fff !important;
    padding: 0 6px;
}

.batsman-name {
    float: left;
    list-style: none;
    padding: 0px;
    margin: 6px 2px;
}
.bg-main {
    height: 100%;
    width: 100%;
    min-height: 185px !important;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.scoremain {
    background-size: 100% 100%;
    padding: 15px;
    width: 100%;
    float: left;
    height: 100%;
    position: relative;
    color: #333;
}
.table-responsive {
    border-radius: 5px;
}

.ballstatus{
    color: #000;
}
.batsmanscorecard {
    padding: 15px;
    margin-bottom: 0px;
    background: rgb(247 247 247);
    font-size: 13px;
    min-height: 90px;
    border-radius: 5px;
}
.padding-zero {
    padding: 0;
}
.batsman-area {
    display: table;
    text-align: left;
    width: 100%;
}
.batsmanul {
    display: table;
    margin: 0;
    width: 100%;
    padding: 0;
}
.bat1name {
    color: #333;
    padding: 0px 3px;
}
.batsmanul tr, th {
    border: 1px solid #fff0 !important;
}
.batsmanul th {
    background-color: hsl(0deg 0% 89.91%);
    color: #333;
}
.batsmanul th, td {
    padding: 12px;
    text-align: center;
    border: 1px solid #000;
}
.batsmanul th, td {
    padding: 12px;
    text-align: center;
    border: 1px solid #fff;
}
.sportimg {
    margin-right: 4px;
    /* filter: invert(1); */
    width: 18px;
}
.score-over-fter {
    display: table;
    text-align: left;
    padding: 0;
}
.score-over > ul {
    display: inline-flex;
    margin: 0;
    width: 100%;
    padding: 0;
}
.ball2 {
    background-color: #48a23c;
    border: 1px solid #48a23c;
    border-radius: 50px;
    color: #fff !important;
    padding: 0 6px;
    width: 22px;
    text-align: center;
}
.ball4 {
    background-color: #2d90d4;
    border: 1px solid #2d90d4;
    border-radius: 50px;
    color: #fff !important;
    padding: 0 6px;
    width: 22px;
    text-align: center;

}
.team-name {
    margin-bottom: 0;
}
.comment-area {
    overflow: hidden;
    display: flex;
    align-items: center;
    place-content: center;
    font-size: 15px;
}
.ballstatus {
    line-height: 24px;
    margin: 0;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
.mod-header {
    background: var(--theme-barheading-background);
    color: #fff;
    padding: 7px;
    position: relative;
    height: 35px;
    width: 100%;
    float: left;
    /* border-top-left-radius: 5px; */
    /* border-top-right-radius: 5px; */
}
.currunt_score {
    color: #242424;
    line-height: 2;
    font-weight: 600;
}
.rulsesmae{
    float: right;
    background: rgb(255, 255, 255);
    border-radius: 15px;
    padding: 0px 5px;
    color: rgb(255, 0, 0);
    }
.teamtype {
    display: -webkit-inline-box;
}
.batsman-name {
    float: left;
    list-style: none;
    padding: 0px;
    margin: 6px 2px;
}
.sportimg {
    margin-right: 4px;
    /* filter: invert(1); */
    width: 18px;
}
/* .thead-dark tr, th {
    border: 1px solid #fff !important;
} */
.thead-dark tr, th {
    border: 1px solid #b1b1b1 !important;
}
.tbody th, td {
    padding: 12px;
    text-align: center;
    border: 1px solid #000;
}
.left_tx button {
    line-height: 1;
}
.box-price .btn{
    margin-right: 10px;
}
.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #58b1ff;
    --bs-btn-border-color: #58b1ff;
}
.table .thead-dark th {
    /* padding: 8px !important; */
}
.resul_ma9in li {
    list-style: none;
    margin-left: 10px;
    background: forestgreen;
    padding: 8px 15px;
    border-radius: 40px;
    COLOR: red;
    text-transform: capitalize;
    font-weight: 600;
}
.result-b {
    color: yellow  !important;
    font-weight: 600;

}
.resul_ma9in {
    display: flex;
    margin: 8px;
    justify-content: flex-end;
}
.team-name {
    margin-bottom: 0;
    color: #fff;
}
.nems_ff {
    margin-bottom: 0px;
}
.card .table td, .card .table th {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}
.inplymach .dd {
    margin-right: 4px;
}
.team-name {
    font-size: 14px;
    line-height: 2.1;
}
.text-xl-end span{
    font-size: 12px;

}
.game-header span{
    color: #fff;
}
.table-flush tbody tr td span {
    font-size: 9px;
    color: #000 ;  
}
.media-body.red_tx span {
    color: white !important;
}
.credit_blue span{
    color: #0077ff !important;
}
.debit_red span{
 color: red !important;
}
.won_blue{
    color: #0077ff !important;
}
.minus_red{
    color: red !important;  
}
.plus_blue{
    color: #0077ff !important;
    
}
.text-left_discrip{
    text-align: start;
}

@media(max-width: 767px) {
    .search-area .search-box {
        grid-template-columns: repeat(2, 1fr)
    }
    .top_matches__main.top_matches__main2 {
        margin: 0px 0 0 10px;
    }
    .middle-iconfixed {
        top: 12% !important;
        margin-left: 18px;
        padding: 9px;
        border-radius: 7px 7px 7px 7px;
    }
    .thead-dark tr, th {
        /* border: 1px solid #58b1ff !important; */
    }
    .box-price button {
        padding: 4px 12px !important;
    }
    .scoremain {
        padding: 0px;
    }
    
    .pdg{
        padding: 0px;
    }
    .card .table td, .card .table th {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }
    .table-flush tbody tr:first-child td, .table-flush tbody tr:first-child th {
        border-top: 0;
        /* width: 210px; */
    }
    .left_tx {
        font-size: 10px !important;
    }
    .tabel_fs {
        /* width: 120px; */
        font-size: 10px;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }
    .media.align-items-center {
        width: 50px;
        margin: auto;
    }
    .mobile_bb{
        padding: 0;
    }
    .mobile_bb .col-lg-12{
        padding: 0;
    }
    .profilenmae .name-tx {
        margin-left: 0px;
        margin-bottom: 0px;
        border: solid 0px #d6d6d6;
        padding: 2px 0px;
        border-radius: 4px;
        box-shadow: 0px 0px 0px #e8e8e8;
        background: hsla(0, 0%, 100%, 0);
        color: #ffffff;
        text-align: start;
        font-size: 12px;
    }
    .header {
    margin-bottom: 0px;
}
    .nems_ff {
        margin-bottom: 10px;
        background-color: #ff669d;
    }
    .inplymach .table-flush tbody tr td span {
        color: #000000;
    }
    .inplymach .table-flush .media-body .mb-0.text-sm {
        color: #ffffff !important;
    }
    .left_tx .btn {
        margin-bottom: 0px;
    }
    .statment_bt .media-body .mb-0.text-sm {
        font-size: 9px !important;
        /* color: #666; */
        white-space: normal;
        text-align: start;

    }
    .discription_as{
        /* color: #666 !important; */
        font-size: 9px !important;
    }
    
    .leager.media.align-items-center {
        width: 160px;
        margin: auto;
        text-align: start;
    }
}

