.ander_bhar2 .image_casino{
    height: 400px;
    width: 100%;
    background-size: cover;
    background-image:    linear-gradient(45deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)), 
    url('/public/image/andarbahar2.jpg');
    
}
.ander_bhar2 .image_casino .slider_card {
    position: absolute;
    top: 100px;
    background: #0000006b;
    padding: 5px;
    width: 570px;
    left: 0;
    right: 0;
    margin: auto;
}

.ander_bhar2 .justyfy_card {
    justify-content: center;
}
.ander_bhar2 .card53 {
    width: 45px;
    height: 50px;
}

.ander_bhar2 .slick-slide img {
    display: block;
    padding: 0px;
    width: 45px;
    height: 50px;
}

@media (max-width: 768px) {
    .ander_bhar2 .image_casino .slider_card {
        position: absolute;
        top: 90px;
        background: #0000006b;
        padding: 5px;
        width: 360px;
        left: 0;
        right: 0;
        margin: auto;
    }
}

/* ============================= */

