body{
    background: hsl(0, 0%, 90%);
}
.sec_desbord_card {
    background: #529fe1;
    text-align: center;
    margin-bottom: 30px;
    padding: 0px 10px 1px;
    box-shadow: 1px 8px 14px #00000059;
    border-radius: 20px;
}
.sec_desbord_card:hover {
    background: #4588c2;
    text-align: center;
    margin-bottom: 30px;
    padding: 0px 10px 1px;
    box-shadow: 0px 0px 0px #0000002e;
    transition: 0.3s;
}
.sec_desbord_tx p {
    margin-bottom: 5px;
}
.sec_desbord_img img {
    width: 110px;
    height: 110px;
}
a {
text-decoration: none;
}
.header {
margin-top: 0px;
margin-bottom: 20px;
background: #fff;
padding: 20px;
}
.formm-sec{
    margin-top: 180px;
}
.sec_desbord_img {
    background: white;
    min-height: 130px;
    border-radius: 0px 0px 200px 200px;
    width: 180px;
    margin: auto;
    background-image: linear-gradient(75deg, #fcfcf2, #fcfcfe);
    display: flex;
    justify-content: center;
}

.sec_desbord_tx p {
font-weight: 500;
font-size: 24px;
color: #fff;
}
.logoout {
text-align: end;
display: flex;
justify-content: flex-end;
align-items: center;
}
.bg_new .row {
align-items: center;
}
.row a{
    text-decoration: none;
}

.logoout button {
background: hsl(343.59deg 73.56% 34.12%);
border: 0;
border-radius: 5px;
padding: 10px 40px;
font-size: 18px;
font-weight: 500;
color: #ffff;
}
.profilenmae {
text-align: end;
display: flex;
justify-content: flex-end;
}
.profilenmae .name-tx {
margin-left: 20px;
margin-bottom: 10px;
}
.profilenmae .name-tx {
    margin-left: 20px;
    margin-bottom: 10px;
    border: solid 1px #d6d6d6;
    padding: 2px 26px;
    border-radius: 4px;
    box-shadow: 1px 1px 5px #e8e8e8;
    background:  hsl(210deg 10.81% 14.51%);
    color: #fff;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #fff;
    background-color: #529fe1;
    border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-link {
    color: hsl(343.59deg 73.56% 34.12%);
}

.video-container {
    position: relative;
    cursor: pointer;
  }
  
  .video-thumbnail {
    width: 100%;
    height: auto;
  }
  
  .overlay-text {
    position: absolute;
    top: 10px;
    left: 10px;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 5px;
    border-radius: 3px;
  }
  .marquee {
    width: 100%;
    overflow: hidden;
    position: relative;
    background: #fff2f2; /* Background color */
    border: 0px solid #ccc; /* Border color */
    margin-bottom: 5px;
}

.marquee p {
    display: inline-block;
    white-space: nowrap;
    padding-left: 100%; /* Start position off-screen */
    animation: marquee 30s linear infinite;
    margin-bottom: 0;
    font-size: 12px;
}

@keyframes marquee {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-100%);
    }
}
.text-color-changing {
    font-size: 12px; /* Adjust font size as needed */
    font-family: Arial, sans-serif;
    text-align: center;
    animation: textColorChange 5s linear infinite;
}
@keyframes textColorChange {
    0% {
        color: #FF669D; /* Red */
    }
    33% {
        color: #000000; /* Green */
    }
    66% {
        color: #58B1FF; /* Blue */
    }
    100% {
        color: #ff0000; /* Red, loop back to start color */
    }
}
@media (max-width: 767px) { 
    .marquee p {
        font-size: 12px;
    }
    .sec_desbord_img {
        min-height: 90px;
        border-radius: 0px 0px 200px 200px;
        width: 110px;
    }
    .profilenmae {
        text-align: center;
        display: flex;
        justify-content: space-around;
    }
.sec_desbord_img img {
width: 75px;
height: 75px;
}
.sec_desbord_tx p {
    font-size: 14px;
    margin-bottom: 0;
}
.sec_desbord_card {
    margin-bottom: 10px;
    padding: 0px 5px 0px;
}
.logo img {
    width: 100%;
}
.profilenmae .name-tx {
    padding: 2px 7px;
}
.profilenmae .name-tx {
    margin-left: 8px;
}
.formm-sec {
    margin-top: 120px;
}
.play-icon svg {
    width: 30px;
}
.sec_desbord_card {
    border-radius: 10px;
}
.video-container img {
    border-radius: 10px;
}
.video-container {
    border-radius: 10px;
}
}

