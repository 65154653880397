.kuber .table-container {
  overflow-x: auto;
  margin: 0 auto;
  padding: 1em;
}

.kuber .responsive-table {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
}

.kuber .header-cell {
  font-size: 2.5em;
  padding: 4px;
  background: #3f51b5;
  color: #fff;
}

.kuber .date-row th {
  width: 143px;
}

.kuber .vertical-text {
  writing-mode: vertical-rl;
  text-orientation: upright;
}
.kuber .r {
  color: #FC4445 !important;
}
.kuber td {
  color: #000;
  font-size: 25px;
  font-weight: 700;
  padding: 0px;
  margin: 0px;
}
.kuber table tr td:nth-child(1), table tr td:nth-child(2), table tr td:nth-child(5), table tr td:nth-child(8), table tr td:nth-child(11), table tr td:nth-child(14), table tr td:nth-child(17), table tr td:nth-child(20), table tr td:nth-child(23) {
  border-left: 1px solid #b3b3b3;
  padding-left: 5px;
}
.kuber table, th, td {
  border-bottom: 1px solid #b3b3b3; 
}
@media (max-width: 768px) {
  .kuber .responsive-table {
      font-size: 0.8em;
  }

  .kuber .header-cell {
      font-size: 2em;
  }
}

@media (max-width: 480px) {
 .kuber .responsive-table {
      font-size: 0.7em;
  }

  .kuber .header-cell {
      font-size: 1.5em;
  }

  .kuber .date-row th {
      width: auto;
  }
}


/* kuberStyle.css */

.table-container table,
.table-container th,
.table-container td {
  border: 1px solid #b3b3b3;
}

.table-container table {
  border-collapse: collapse;
}



/* ================ CALCULATOR CSS ====================== */

.placeamountnum{
  background-color: rgb(243, 224, 224);
  font-size: 20px;
  text-align: center;
  height: 35px;
}
.clear{
  background-color: green;
}
.calculator-grid {
  display: grid;
  gap: 2px;
  margin-top: 1rem;
  justify-content: center;
  grid-template-columns: repeat(4, 4rem);
  grid-template-rows: minmax(2rem, auto) repeat(2, 3rem);
}

.calculator-grid > button {
  cursor: pointer;
  font-size: 1rem;
  outline: none;
  /* background-color: rgba(255, 255, 255, .75); */
  border: 1px solid #b3b3b3;
  box-shadow: 0px 0px 10px rgba(161, 149, 149, 0.5); 
}

/* .calculator-grid > button:hover,
.calculator-grid > button:focus {
  background-color: rgba(255, 255, 255, .9);
} */

.span-two {
  grid-column: span 2;
}

.span-two.equal {
  color: #67298b;
}
.span-two.clean {
  color: crimson;
}

.output {
  grid-column: 1 / -1;
  background-color: rgba(0, 0, 0, .75);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
  /* padding: .75rem; */
  word-wrap: break-word;
  word-break: break-all;
  min-height: 40px;
}

.output .previous-operand {
  color: rgba(255, 255, 255, 0.75);
  font-size: 1.5rem;
}

.output .current-operand{
  color: white;
  font-size: 16px;
}

.previous-operand {
  color: #888;
}

.button-clear {
  background-color: red;
  color: #fff;
}
.button-ok{
  background-color: green;
  color: #fff;
}
.cdsd button {
  width: 137px;
}

.heading p{
  font-size: 14px;
  font-weight: 600;
}
.d-flex.flex-wrap.cdsd {
  justify-content: center;
}
.header_close {
  padding: 0;
  border: 0;
  justify-content: end;
  padding: 4px;
  position: absolute;
  right: 0;
  z-index: 99;
}
.main_button {
  text-align: end;
  padding: 0px 15px;
}
.lower-heading{
  /* margin-top: 60px; */
  text-align: center;
  margin-left: 44px;
}

.lower-heading p{
  font-weight: 600;
}
.date_name ul {
  padding: 0;

}
.cdsd button {
  padding: 10px;
  border: 0;
  margin: 5px;
}
.date_name ul li{
 list-style: none;
 text-align: center;
}
@media (max-width: 768px) {
  button {
      width: 100%;
      padding: 8px;
      margin: 5px 0;
  }
  .kuber td {
    color: #000;
    font-size: 10px;
  }
  .kuber .date-row th {
    width: auto;
    padding: 6px;
}
.kuber .table-container{
  padding: 0;
}
.cdsd button {
  width: 70px;
}
.header_close {

  width: 50px;
}

}
@media (max-width: 480px) {
  .kuber .date-row th {
      width: auto;
      padding: 6px;
  }
}