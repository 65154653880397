.live-casino-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.video-containers {
  width: 100%;
  /* max-width: 864px;  */
  margin-bottom: 20px;
}
.amar_bg1 {
  background-color: #72bbef !important;
}
.amar_bg2 {
  background-color: #faa9ba !important;
}

.id_userfac {
  text-align: end;
}
.video_hh{
  position: relative;
  width: 100%,;
  height: 470px;
  overflow: hidden;
}
.slider_time {
  position: absolute;
  bottom: 0px;
  right: 0;
  background: #0000006b;
  padding: 5px;
  width: 60px;
}
.slider_time p {
  padding: 5px;
  font-size: 25px !important;
  font-weight: 600;
  color: #fff;
}

.card-toggle-btn {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 20px;
}

.card-info {
  font-size: 18px;
  color: #333;
}
.ander_bahar p {
  margin-bottom: 0px;
  text-align: center;
  font-size: small;
}
.ader_b_box {
  background: #e9e9e9;
}
.redzone p {
  color: red;
}

.ader_b_box {
  position: relative;
}
.box-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 69%);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  border-radius: 0px;
}
.ader_b_box {
  background: #6e87ad;
  color: #fff;
  font-weight: 700;
  min-height: 40px;
}
.user_bk_dd {
  background-color: #2d90d4;
  color: #e9e9e9;
}
.video-container body {
  overflow: hidden !important;
}
.ader_b_box p {
  font-weight: 700;
}
.card_lo img {
  line-height: 5;
  margin-top: 8px;
}
.icon {
  position: absolute;
  top: 7px;
  right: 0;
  left: 0;
  margin: auto;
  width: 22px;
  height: 29px;
  background-size: cover;
  background-image: url(/public/images/anderlock.svg);
}
.title_ander h2 {
  font-size: 24px;
  font-weight: 600;
}
.Result_v {
  text-align: start !important;
  font-weight: 600;
  color: #fff;
}
.statment_v {
  text-align: end !important;
  font-weight: 600;
  color: #fff;
}
.bg_rusls {
  background-color: #2d90d4;
}
.border_carander {
  border: 5px solid #2d90d4;
}
.redzonesx p {
  font-size: 16px;
  font-weight: 600;
}
.deckcard {
  background: #ffffff;
  color: #f00;
  font-weight: 700;
}
.pricecard_sec {
  text-align: center;
  font-size: 20px !important;
  font-weight: 600;
}
.icons {
  top: 30px;
}
.even_n {
  font-size: 24px !important;
  font-weight: 600;
}
.odd_n {
  font-size: 24px !important;
  font-weight: 600;
}
.video-containers {
  position: relative;
}
.slider_card ul li {
  float: left;
  margin-left: 4px;
  list-style: none;
}
.slider_card ul li img {
  width: 20px;
}
.slick-slide img {
  display: block;
  padding: 8px;
}
.slider_card ul {
  padding: 0;
}
.bahr_text {
  text-align: start !important;
  font-size: 15px;
  font-weight: 600;
  color: #fff;
  margin: 0;
  padding: 0;
}
.justyfy_card {
  align-items: center;
}
.deckcard .icon {
  top: 25px;
}
.title_ander h2 {
  padding: 4px;
}
.slider_card {
  position: absolute;
  top: 0px;
  background: #0000006b;
  padding: 5px;
  width: 250px;
}
.card52 {
  width: 30px;
  height: 41px;
}
.card53 {
  width: 21px;
  height: 34px;
}
.anader_bb {
  padding: 12px 0px;
}
.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgb(255 255 255) !important;
}
.bg-danger.timeers {
  border-radius: 5px;
  padding: 19px;
  height: 26px;
  line-height: 0;
  color: #fff;
}
.ander_bahar .table-responsive {
  border-radius: 0px;
}
.ander_bahar .bg-default {
  border-radius: 0;
}
@media (max-width: 768px) {
  .title_ander h2 {
    font-size: 18px;
    font-weight: 600;
}
.bg-danger.timeers {
  margin-top: 6px;
}
  .card-info {
    font-size: 16px;
  }
  .card-toggle-btn {
    font-size: 14px;
    padding: 8px 16px;
  }
  .title_ander h2 {
    padding: 4px !important;
  }
  .video_hh {
    height: 190px;
}
.ander_bahar.container{
  padding: 0;
}
.live-casino-container {
  padding: 4px;
}
.border_carander {
  border: 2px solid #97173a;
}
.resul_ma9in li {
  margin-left: 4px;
  padding: 4px 7px;
  font-size: 8px;
}
#target-element {
  padding: 0;
}
.id_userfac {
  font-size: 10px;
}
.id_username {
  font-size: 10px;
}
.card52 {
  width: 30px;
  height: 40px;
}
.slider_card {
  width: 180px;
}
.allGames {
  margin-bottom: 0 !important;
}
}
