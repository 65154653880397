.slider_card1 ul li {
    float: left;
    margin-left: 4px;
    list-style: none;
  }
  .slider_card1 ul li img {
    width: 20px;
  }
  .slick-slide img {
    display: block;
    padding: 4px;
  }
  .slider_card1 ul {
    padding: 0;
  }
  .play1 p {
    color: #fff;
    text-align: start;
    font-size: 16px;
    font-weight: 500;
  }
  .slider_card1 {
    position: absolute;
    top: 0px;
    background: #0000006b;
    padding: 5px;
    width: 125px;
}
.play1 img {
  margin-right: 5px;
}
.slider_time{
    position: absolute;
    bottom: 0px;
    right: 0;
    background: #0000006b;
    padding: 5px;
    width: 60px;
  }
  .slider_time p{
    padding: 5px;
    font-size: 25px !important;
    font-weight: 600;
    color: #fff;
  }