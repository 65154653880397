.wrap1 {
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-sizing: border-box;
    /* height: 100vh; */
    padding: 0rem;
    background-color: #eee;
  }
  
  .container1 {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 1rem;
    margin-top: 0px;
    background-color: #fff;
    height: 100%;
    border-radius: 0.25rem;
    box-shadow: 0rem 1rem 2rem -0.25rem rgba(0, 0, 0, 0.25);
    
    &__heading1 {
      padding: 1rem 0;
      border-bottom: 1px solid #ccc;
      text-align: center;
      
      & > h2 {
        font-size: 1.75rem;
        line-height: 1.75rem;
        margin: 0;
      }
    }
    
    &__content1 {
      flex-grow: 1;
      overflow-y: scroll;
    }
    
    &__nav1 {
      border-top: 1px solid #ccc;
      text-align: right;
      padding: 2rem 0 1rem;
      
      & > .button1 {
        background-color: #444499;
        box-shadow: 0rem 0.5rem 1rem -0.125rem rgba(0, 0, 0, 0.25);
        padding: 0.8rem 2rem;
        border-radius: 0.5rem;
        color: #fff;
        text-decoration: none;
        font-size: 0.9rem;
        transition: transform 0.25s, box-shadow 0.25s;
        
        &:hover {
          box-shadow: 0rem 0rem 1rem -0.125rem rgba(0, 0, 0, 0.25);
          transform: translateY(-0.5rem);
        }
      }
      
      & > small {
        color: #777;
        margin-right: 1rem;
      }
    }
  }