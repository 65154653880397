/* body {
  background: #000 url("../../assets/image/bg1.jpg") no-repeat center -80px ;
  background-size: cover;
  color: #f1c876;
} */

.ggg .table {
  margin: 10px auto;
  padding: 20px;
  border: 2px solid #dcb44a;
  border-radius: 16px;
  background: linear-gradient(to bottom, #121212, #0e1111, #121212); 
  width: 100%;
  }
  
  .ggg .divider-line {
  position: relative;
  border-top-width: 1px;
  margin: 0 0 20px 0;
  text-align: center;
  left: auto;
  right: auto;
  }
  
  .ggg .divider-line-center {
  text-align: center; 
  }
  
  .ggg .divider-line-linear-gradient {
  height: 1px;
  border: none;
  background-image: linear-gradient(to right, transparent, #b0762b, #dcb44a, transparent);
  }
  
  .ggg .diamond-line-icon {
  position: absolute;
  top: -11px;
  width: 34px;
  height: 24px;
  font-size: 16px;
  line-height: 16px;
  text-align: left;
  font-style: normal; 
  color: #db1f27;
  background-color: #8B0000; 
  }
  
  .ggg .divider-line-center .diamond-line-icon {
  left: auto;
  right: auto;
  margin-left: -1.42857rem; }
  
  .ggg .bg-verdict {
  margin-top: 20px;
  padding: 10px;
  border-radius: 10px;
  border: 3px solid #F2F098;
  max-width: 700px!important;
  flex-wrap: wrap;
  word-wrap: break-word;
  min-height: 100px;
  color: #F2F098;
  }
  
  /* Global styles */
  
  .ggg .text-small {
  font-size: 90%;
  }
  
  .ggg .pt-6 {
  padding-top: 6px;
  }
  .ggg .bg-red {
  background-color: #8B0000;
  }
  
  .ggg .text-light-gold {
  color: cornsilk;
  }
  
  .ggg .text-gold {
  color: #f1c876;
  }
  
  .ggg .coins-col {
  background: url("../../assets/image/button-bg.png") center center no-repeat;
  background-size: contain;
  min-height: 150px;
  }
  
  
  .ggg .pretty-italic {
  font-family: "Kaushan Script", "corsive";
  color: #FB4264;
  text-shadow: 0 0 3vw #F40A35;
  }
  
  .ggg .neon {
  animation: neon 1s ease infinite;
  -moz-animation: neon 1s ease infinite;
  -webkit-animation: neon 1s ease infinite;
  }
  
  @keyframes neon {
  0%,
  100% {
    text-shadow: 0 0 1vw #FA1C16, 0 0 3vw #FA1C16, 0 0 10vw #FA1C16, 0 0 10vw #FA1C16, 0 0 .4vw #FED128, .5vw .5vw .1vw #806914;
    color: #FED128;
  }
  50% {
    text-shadow: 0 0 .5vw #800E0B, 0 0 1.5vw #800E0B, 0 0 5vw #800E0B, 0 0 5vw #800E0B, 0 0 .2vw #800E0B, .5vw .5vw .1vw #40340A;
    color: #806914;
  }
  }
  
  .ggg .blink {
  animation: blink 1.2s infinite;
  }
  @keyframes blink{
  0%    {color: #b0762b;}
  50%   {color: #dcb44a;}
  60%   {color: #dcb44a;}
  70%   {color: #dcb44a;}
  100%  {color: #b0762b;}
  }
  
  .ggg .custom-btn {
  background: linear-gradient(to bottom , #b0762b, #dcb44a)!important;
  
  border: 1px solid #f1c876!important;
  color: #121212!important;
  font-weight: 500!important;
  font-family: 'Coda', cursive;
  text-transform: uppercase;
  padding: 6px 10px 2px 10px!important;
  }
  .ggg .custom-btn:hover {
  background: linear-gradient(to bottom , #dcb44a, #b0762b)!important;
  border-image-source: linear-gradient(45deg, #b0762b, #dcb44a, #b0762b)!important;
  border-image-slice: 1!important;
  border: 1px solid #f1c876!important;
  }
  
  .ggg .text-gold {
  color: #f1c876;
  }
  
  .ggg .text-light-gold {
  color: cornsilk;
  }
  
  .ggg .bg-blackish {
  background-color: #121212!important;
  }
  
  .ggg .border-gold {
  border: 1px solid #dcb44a;
  }
  
  .ggg .logo {
  max-height: 120px;
  }
  .ggg .tabl{
    background-color: #800E0B !important;
    color: #F2F098 !important;
  }
  .money_adedd ul{
    padding: 0;
  }
  .money_adedd ul li{
    background-color: #333;
    float: left;
    margin-right: 5px;
    padding: 7px 5px;
    color: #fff;
    list-style: none;
    border-radius: 5px;
    width: 60px;
    text-align: center;
  }
  .money_adedd {
    margin-top: 24px;
}
  @media (max-width: 768px) {
    .ggg .coins-col {
        font-size: 1.2rem;
    }
    .amountt form.mt-0 {
      display: grid;
  }
    .ggg .divider-line {
        margin: 2rem 0;
    }
  }
  
  @media (max-width: 576px) {
    .ggg .coins-col {
        font-size: 1rem;
    }
  
    .ggg .divider-line {
        margin: 1rem 0;
    }
  
    .ggg .list-inline-item {
        display: block;
        margin: 5px 0;
    }
    .ggg .tabl {
      background-color: #800E0B !important;
      color: #F2F098 !important;
      height: 332px;
      width: 204%;
  }
  .ggg #spinn {
    background-color: #000000 !important;
    height: 201px;
    width: 49%;
  }
  .ggg .roulette-canvas {
    /* margin: 20px; */
    /* height: 340px; */
  }
  }

  /* .btnn button{
 margin-left: 84%;
 padding: 6px 0px 6px 2px;
  } */

  .ggg .bets-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center content horizontally */
}

.ggg .btn-done {
    /* Customize your button here */
    background-color: #000; /* Example color */
    border: none;
    color: white;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
}

.ggg .btn-done:hover {
    background-color: #121212; /* Darker shade on hover */
}

.ggg .text-light-gold {
    color: #d4af37; /* Example color */
}
.ggg {
  margin: 10px auto;
  padding: 20px;
  border: 2px solid #dcb44a;
  border-radius: 16px;
  background: linear-gradient(to bottom, #121212, #0e1111, #121212);
  width: 100%;
  background-image: url(../../../public/image/gradient-poker-table-backgroung.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0px -110px;
  height: 620px;
}
.scal_size {
  scale: 0.9;
  transform: translate(240px, 10px);
}
.tabl {
  scale: 0.8;
  width: 370px;
}
.roulette-container.align-self-start {
  scale: 0.8;
}
.ratebetrr .bets-container {
  background: #fff0;
  border-radius: 10px;
  min-height: 44px;
  height: 44px;
}
.ratebetrr {
  position: absolute;
  bottom: 0px;
  width: 10%;
}
.ratebetrr .bets-container p{
  color: #000;
  font-weight: 600;
}
@media only screen and (max-width: 767px) {

  .ggg {
    background-image: url(../../../public/image/gradient-poker-table-backgroung-mobile.jpg);
    height: 600px;
    background-position: 0px 0px;
  }
  .ggg .scal_size{
    /* transform: scale(.7); */
    /* transform: rotate(90deg); */
  }
  .ggg {
    transform: scale(1);
    /* transform: rotate(90deg); */
  }
/* .roulette-container.align-self-start {
    transform: scale(0.4);
    position: absolute;
    left: 300px;
} */
.ggg .tableee.row {
  transform: rotate(90deg) !important;
  width: auto;
  height: 60%;
}
.roulette-container.align-self-start {
  transform: scale(.5);
        margin-left: 100px;
        margin-top: 60px;
}
.bg-red.bg-verdict.align-items-center.tabl.row {
  transform: scale(.5);
}
.ggg .d-flex.flex-row.align-items-start.roulette-table.tablee {
  transform: scale(0.9);
  margin-left: 2px;
  margin-top: 30px;
}
.ggg .tabl {
  height: 343px;
  width: 350px;
  margin-left: -200px !important;
  margin-right: 0px !important;
  margin-top: -97px !important;
}
.rolletesmoble{
  transform: rotate(90deg);
}
.rolletesmoble .main-price {
  transform: scale(0.5);
  position: absolute;
  left: -360px;
  top: -72px;
}
.scal_size {
  scale: 0.8;
  transform: translate(70px, 10px);
}
.ratebetrr {
  bottom: 360px;
  width: 40%;
  transform: rotate(90deg);
  margin-left: 281px;
  margin-bottom: -150px;
}
}

